import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { NavigationSaService } from "../../services/navigationSa.service";
import { NavigationUserService } from "../../../shared/services/navigationUser.service";
import { ThemeService } from "../../services/theme.service";
import { Subscription } from "rxjs";
import { ILayoutConf, LayoutService } from "app/shared/services/layout.service";
import { JwtAuthService } from "app/shared/services/auth/jwt-auth.service";
import { NavigationProfessorService } from "app/shared/services/navigationProfessor.service";

@Component({
  selector: "app-sidebar-side",
  templateUrl: "./sidebar-side.component.html"
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
  public menuItems: any[];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  private menuItemsSub: Subscription;
  public layoutConf: ILayoutConf;

  constructor(
    private navService: NavigationSaService,
    private navProfesorService: NavigationProfessorService,
    private navUserService: NavigationUserService,
    public themeService: ThemeService,
    private layout: LayoutService,
    public jwtAuth: JwtAuthService
  ) {}

  ngOnInit() {
    let navigation;
    let role: string = '';

    role = this.jwtAuth.getUser().role;

    switch (role) {
      case "Administrador":
        navigation = this.navService;
        break;
      case "Profesor":
      case "Supervisor":
        navigation = this.navProfesorService
        break;
      case "Alumno":
        navigation = this.navUserService;
        break;
      default:
        break;
    }

    this.iconTypeMenuTitle = navigation.iconTypeMenuTitle;
    this.menuItemsSub = navigation.menuItems$.subscribe(menuItem => {
      this.menuItems = menuItem;
      //Checks item list has any icon type.
      this.hasIconTypeMenuItem = !!this.menuItems.filter(
        item => item.type === "icon"
      ).length;
    });
    this.layoutConf = this.layout.layoutConf;
  }
  ngAfterViewInit() {}
  ngOnDestroy() {
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
  }
  toggleCollapse() {
    if (
      this.layoutConf.sidebarCompactToggle
    ) {
        this.layout.publishLayoutChange({
        sidebarCompactToggle: false
      });
    } else {
        this.layout.publishLayoutChange({
            sidebarStyle: "compact",
            sidebarCompactToggle: true
          });
    }
  }
}
