import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuItem, ChildItem, Badge } from '../models/navigation.model';

@Injectable()
export class NavigationProfessorService {
    iconMenu: MenuItem[] = [
        {
            name: 'Inicio',
            type: 'link',
            tooltip: 'Inicio',
            icon: 'home',
            state: 'dashboard'
        },
    ];


    /*iconMenu: MenuItem[] = [
        {
            name: 'Inicio',
            type: 'link',
            tooltip: 'Inicio',
            icon: 'home',
            state: 'dashboard'
        },
        {
            type: 'separator',
            name: 'Reportes'
        },
        {
            name: 'Por rendimiento',
            type: 'link',
            tooltip: 'Reporte por rendimiento',
            icon: 'description',
            state: 'reportes/rendimiento'
        },
        {
            name: 'Por programa',
            type: 'link',
            tooltip: 'Reporte por programa',
            icon: 'description',
            state: 'reportes/programas'
        },
        {
            name: 'Por alumno',
            type: 'link',
            tooltip: 'Reporte por alumno',
            icon: 'description',
            state: 'reportes/alumnos'
        },
        {
            name: 'Por pruebas realizadas',
            type: 'link',
            tooltip: 'Reporte pruebas realizadas',
            icon: 'description',
            state: 'reportes/pruebas'
        },

    ];*/

    separatorMenu: MenuItem[] = [
        {
            type: 'separator',
            name: 'Custom components'
        },
        {
            name: 'DASHBOARD',
            type: 'link',
            tooltip: 'Dashboard',
            icon: 'dashboard',
            state: 'dashboard'
        },
        {
            name: 'PROFILE',
            type: 'dropDown',
            tooltip: 'Profile',
            icon: 'person',
            state: 'profile',
            sub: [
                { name: 'OVERVIEW', state: 'overview' },
                { name: 'SETTINGS', state: 'settings' },
                { name: 'BLANK', state: 'blank' }
            ]
        },
        {
            type: 'separator',
            name: 'Integrated components'
        },
        {
            name: 'FORMS',
            type: 'dropDown',
            tooltip: 'Forms',
            icon: 'description',
            state: 'forms',
            sub: [
                { name: 'BASIC', state: 'basic' },
                { name: 'EDITOR', state: 'editor' },
                { name: 'UPLOAD', state: 'upload' },
                { name: 'WIZARD', state: 'wizard' }
            ]
        },
        {
            name: 'TABLES',
            type: 'dropDown',
            tooltip: 'Tables',
            icon: 'format_line_spacing',
            state: 'tables',
            sub: [
                { name: 'FULLSCREEN', state: 'fullscreen' },
                { name: 'PAGING', state: 'paging' },
                { name: 'FILTER', state: 'filter' }
            ]
        },
        {
            name: 'CHARTS',
            type: 'link',
            tooltip: 'Charts',
            icon: 'show_chart',
            state: 'charts'
        },
        {
            type: 'separator',
            name: 'Other components'
        },
    ];

    plainMenu: MenuItem[] = [
        {
            name: 'DASHBOARD',
            type: 'link',
            tooltip: 'Dashboard',
            icon: 'dashboard',
            state: 'dashboard'
        },
        {
            name: 'FORMS',
            type: 'link',
            tooltip: 'Forms',
            icon: 'description',
            state: 'forms',
            sub: [
                { name: 'BASIC', state: 'basic' },
                { name: 'EDITOR', state: 'editor' },
                { name: 'UPLOAD', state: 'upload' },
                { name: 'WIZARD', state: 'wizard' }
            ]
        },
        {
            name: 'TABLES',
            type: 'dropDown',
            tooltip: 'Tables',
            icon: 'format_line_spacing',
            state: 'tables',
            sub: [
                { name: 'FULLSCREEN', state: 'fullscreen' },
                { name: 'PAGING', state: 'paging' },
                { name: 'FILTER', state: 'filter' }
            ]
        },
        {
            name: 'PROFILE',
            type: 'dropDown',
            tooltip: 'Profile',
            icon: 'person',
            state: 'profile',
            sub: [
                { name: 'OVERVIEW', state: 'overview' },
                { name: 'SETTINGS', state: 'settings' },
                { name: 'BLANK', state: 'blank' }
            ]
        },
        {
            name: 'CHARTS',
            type: 'link',
            tooltip: 'Charts',
            icon: 'show_chart',
            state: 'charts'
        }
    ];

    // Icon menu TITLE at the very top of navigation.
    // This title will appear if any icon type item is present in menu.
    iconTypeMenuTitle = 'Frequently Accessed';
    // sets iconMenu as default;
    menuItems = new BehaviorSubject<MenuItem[]>(this.iconMenu);
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();
    constructor() { }

    // Customizer component uses this method to change menu.
    // You can remove this method and customizer component.
    // Or you can customize this method to supply different menu for
    // different user type.
    publishNavigationChange(menuType: string) {
        switch (menuType) {
            case 'separator-menu':
                this.menuItems.next(this.separatorMenu);
                break;
            case 'icon-menu':
                this.menuItems.next(this.iconMenu);
                break;
            default:
                this.menuItems.next(this.plainMenu);
        }
    }
}
