<mat-toolbar id="mainbar" class="topbar mat-bg-card">

  <!-- App Logo -->
  <div class="branding-head">

    <!-- Sidenav toggle button -->
    <button
      mat-icon-button
      id="sidenavToggle"
      class="topbar-button-right"
      (click)="toggleCollapse()"
      matTooltip="Toggle Hide/Open">
      <mat-icon>menu</mat-icon>
    </button>
    <div class="app-logo-container">
      <img src="assets/images/MS-original2.png" alt="" class="app-logo">
    </div>
    <!-- <span class="app-logo-text">MultiSkills</span> -->

  </div>

  <span fxFlex></span>

  <!-- Top left user menu -->
  <button
    mat-icon-button
    [matMenuTriggerFor]="accountMenu"
    class="topbar-button-right img-button">
    <img src="assets/images/profile.png" alt="" />
  </button>

  <mat-menu #accountMenu="matMenu">
    <button mat-menu-item [routerLink]="['/mi-perfil']">
      <mat-icon>account_box</mat-icon>
      <span>Cuenta</span>
    </button>
    <!-- <button mat-menu-item [routerLink]="['/profile/settings']">
      <mat-icon>settings</mat-icon>
      <span>Account Settings</span>
    </button>
    <button mat-menu-item>
      <mat-icon>notifications_off</mat-icon>
      <span>Disable alerts</span>
    </button> -->
    <button mat-menu-item (click)="jwtAuth.signout()">
      <mat-icon>exit_to_app</mat-icon>
      <!-- <span>{{ "SIGNOUT" | translate }}</span> -->
      <span>Cerrar sesión</span>
    </button>
  </mat-menu>
</mat-toolbar>
