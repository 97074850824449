import { Injectable } from "@angular/core";
import { LocalStoreService } from "../local-store.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { map, catchError, delay } from "rxjs/operators";
import { IUser, User, IUserData } from "../../models/user.model";
import { of, BehaviorSubject, throwError } from "rxjs";
import { environment } from "environments/environment";
import { Credential } from '../../models/credential.model';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class JwtAuthService {

  helper = new JwtHelperService();

  url = environment.apiURL;

  token;
  isAuthenticated: Boolean;
  user: User;
  // user0: User;
  // user = new User('');
  user$ = new BehaviorSubject<User>(new User(''));
  signingIn: Boolean;
  redirect: string = '';
  return: string;
  JWT_TOKEN = "JWT_TOKEN";
  APP_USER = "EGRET_USER";
  APP_INSTIT_ID = "MS_INSTITUCION_ID"

  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams
      .subscribe(params => this.return = params['return'] || '/' + this.redirect);
  }

  public signin(credential) {
    // FOLLOWING CODE SENDS SIGNIN REQUEST TO SERVER
    this.signingIn = true;
    const headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded');
    const params = new HttpParams({
      fromObject: {
        user: credential.user,
        password: credential.password
      }
    });

    return this.http.post(`${environment.apiURL}/user`, params.toString(), { headers: headers })
      .pipe(
        map((res: any) => {
          this.user = new User(res);
          this.setUserAndToken(res.token, this.user, !!res);
          console.dir(this.user);

          this.http.get<IUserData>(`${environment.apiURL}/usuario/${this.user.id}`)
            .toPromise().then((response) => {
              this.ls.setItem(this.APP_INSTIT_ID, response.idCarrera.idFacultad.idInstitucion.id);
            })

          switch (this.user.role || this.user.role) {
            case "Administrador":
            case "Profesor":
            case "Supervisor":
              this.redirect = "dashboard";
              break;
            case "Alumno":
              this.redirect = "inicio";
              break;
            default:
              this.redirect = "";
              break;
          }
          //console.log(res);

          //const decodedToken = this.helper.decodeToken(res.token);
          //console.log(decodedToken);

          this.signingIn = false;
          return res;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /*
    checkTokenIsValid is called inside constructor of
    shared/components/layouts/admin-layout/admin-layout.component.ts
  */
  public checkTokenIsValid() {
    /*
      The following code get user data and jwt token is assigned to
      Request header using token.interceptor
      This checks if the existing token is valid when app is reloaded
    */

    /*return this.http.get(`${environment.apiURL}/api/users/profile`)
      .pipe(
        map((profile: User) => {
          this.setUserAndToken(this.getJwtToken(), profile, true);
          return profile;
        }),
        catchError((error) => {
          this.signout();
          return of(error);
        })
      );*/
    const token = this.getJwtToken();
    const decoded = this.helper.decodeToken(token);
    const noUser = new User('');

    if (decoded.exp === undefined) {
      this.signout();
      return noUser;
    }

    const date = new Date(0);
    const toDate = new Date();
    let tokenExpDate = date.setUTCSeconds(decoded.exp);

    if (tokenExpDate.valueOf() > toDate.valueOf()) {
      return this.getUser();
    } else {
      this.signout();
      return noUser;
    }

  }

  public signout() {
    if (this.getUser().role == 'Alumno') {
      if (this.ls.getItem('canLogout') == false) {
        alert("No puedes cerrar sesión en este momento.");
        return;
      }
    }

    this.setUserAndToken(null, null, false);
    this.router.navigateByUrl("sessions/signin");
  }

  public clearout() {
    this.setUserAndToken(null, null, false);
    this.ls.setItem(this.APP_INSTIT_ID,null);
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.APP_USER);
    localStorage.removeItem(this.APP_INSTIT_ID);
  }

  isLoggedIn(): Boolean {
    const token = this.getJwtToken();
    return !this.helper.isTokenExpired(token);
  }

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }
  getUser() {
    return this.ls.getItem(this.APP_USER);
  }
  getInstitucion(){
    return this.ls.getItem(this.APP_INSTIT_ID);
  }

  setUserAndToken(token: String, user: User, isAuthenticated: Boolean) {
    this.isAuthenticated = isAuthenticated;
    this.token = token;
    this.user = user;
    this.user$.next(user);
    this.ls.setItem(this.JWT_TOKEN, token);
    this.ls.setItem(this.APP_USER, user);
  }
}
