export const config = {
  //apiUrl: 'https://api.mymultiskills.com',
  apiUrl: 'http://localhost:8080/Multiskills_war_exploded',
  //apiUrl: 'http://localhost:8080',
  //apiUrl: '/api',
  authRoles: {
    sa: ['SA', 'Administrador'], // Only Super Admin has access
    admin: ['SA', 'Administrador'], // Only SA & Admin has access
    editor: ['SA', 'Administrador', 'editor', 'Profesor', 'Supervisor'], // Only SA & Admin & Editor has access
    user: ['SA', 'Administrador', 'editor', 'Profesor', 'Alumno', 'Supervisor'], // Only SA & Admin & Editor & User has access
    guest: ['SA', 'Administrador', 'editor', 'Profesor', 'user', 'guest', 'Supervisor'] // Everyone has access
  }
}
