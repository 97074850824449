import { Deserializable } from "./deserialize.model";

export interface IUser {
  id?: string;
  fullname?: string;
  role?: string;
  pwd?: string;
}

export interface IUserInstitucion {
  descEstado: string
  estado: number
  id: number
  nombre: string
}
export interface IUserFacultad {
  descEstado: string
  estado: number
  id: number
  nombre: string
  idInstitucion: IUserInstitucion
}
export interface IUserCarrera{
  descEstado: string
  estado: number
  id: number
  nombre: string
  idFacultad: IUserFacultad
}

export interface IUserPerfil{
  descEstado: string
  descripcion: string
  estado: number
  id: number
}
export interface IUserData {
  cambioClave: any
  clave: string
  codigo: string
  correo: string
  descEstado: string
  estado: number
  id: number
  idCarrera: IUserCarrera
  idPerfil: IUserPerfil
}

export class User implements IUser, Deserializable{
  id?: string;
  fullname?: string;
  role?: string;
  pwd?: string;

  constructor(user: any) {
    this.id = user.id;
    this.fullname = user.fullname;
    this.role = user.role;
    this.pwd = user.pwd
  }

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
